.interestedWrapper {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 2em;
    overflow: hidden;
}

.tableRowsContainer{
    display: flex;
}

.tableRows{
    border: 1px solid #d3d3d3;
    padding: 10px;
    width: 50%;
}

.tableRowsHeader{
    background-color: #f8f8f8 ;
}

.tableRowsValue{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.status{
    width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        color: #fd7e50;
        border: none;
        background: rgba(101, 34, 120, 0.1);
}
