.homeScreenWrapper{
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
    overflow: hidden;
}

.homeScreenTiles{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px 0 0 20px;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 2em;
}