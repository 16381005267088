* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
    height: auto;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    color: white;
    width: 100%;
    border-bottom: 2px solid red;
}

.form-div {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 40px 0;
    width: 400px;
    max-width: 400px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: left;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
}

.form-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

.form-input:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 3px rgba(0, 86, 179, 0.3);
}

textarea.form-input {
    resize: vertical;
    min-height: 100px;
    margin-bottom: 0;
}

.form-button {
    padding: 10px;
    background-color: #652078;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.form-button:hover {
    background-color: #410e4f;
}

.error {
    color: red;
    font-size: 12px;
    margin-left: 10px;
    text-align: right;
    justify-self: right;
}

.error-input {
    border: 1px solid red;
}

.success-message {
    color: green;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.error-message {
    color: black;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

button:disabled:hover {
    background-color: grey;
}


@media (max-width: 600px) {
    .form-div {
        padding: 20px;
        margin: 20px;
    }

    .form-input {
        font-size: 14px;
    }
}